import React from 'react'
import styles from '../../styles/Home.module.css'
import Play from '../../../public/svgs/Play.svg'
import Link from 'next/link'
import { useRouter } from 'next/router'

const WhiteButton = ({ link, text, blue, style, purpleButton, image, target, onClick }) => {
  const size = '32px'
  const router = useRouter()

  return (
    <>
      {purpleButton ? (
        <Link href={link ? link : '/'} target={target && '_blank'} style={{ textDecoration: 'none' }}>
          <div className={styles.purpleButton} style={style && style}>
            {text}
          </div>
        </Link>
      ) : (
        <Link href={link ? link : '/'} onClick={onClick && onClick} rout style={{ textDecoration: 'none' }}>
          <div className={blue ? styles.blueButton : styles.whiteButton} style={style && style}>
            {image && <Play width={size} />}
            {text}
          </div>
        </Link>
      )}
    </>
  )
}

export default WhiteButton
